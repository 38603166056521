import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Main",
    icon: "",
    component: () => import("../views/Main.vue"),
    children: [
      {
        path: "/Home",
        icon: "el-icon-s-home",
        name: "首页",
        meta: {
          title: "首页 - 青栀吖",
        },
        component: () => import("@/views/Home"),
        childrens: false,
      },
      {
        path: "/teaParty",
        name: "茶话会",
        icon: "el-icon-coffee-cup",
        component: () => import("../views/children/teaParty"),
        childrens: true,
        children: [
          {
            path: "/articleList",
            name: "学习",
            meta: {
              title: "学习 - 青栀吖",
            },
            icon: "el-icon-reading",
            component: () => import("../views/articleList"),
            hidden: false,
            childrens: false,
          },
          {
            path: "/image",
            name: "图片",
            meta: {
              title: "图片 - 青栀吖",
            },
            icon: "el-icon-reading",
            component: () => import("../views/image"),
            hidden: false,
            childrens: false,
          },
        ],
      },
      {
        path: "/music",
        name: "音乐",
        meta: {
          title: "音乐 - 青栀吖",
        },
        icon: "el-icon-headset",
        component: () => import("../views/music"),
        hidden: false,
        childrens: false,
      },
      {
        path: "/520liuyang",
        name: "我爱刘洋",
        meta: {
          title: "我爱刘洋 - 青栀吖",
        },
        icon: "el-icon-magic-stick",
        component: () => import("../views/520liuyang"),
        hidden: false,
        childrens: false,
      },
      {
        path: "/other",
        name: "其他",
        meta: {
          title: "其他 - 青栀吖",
        },
        icon: "el-icon-guide",
        component: () => import("../views/children/other"),
        hidden: false,
        childrens: true,
        children: [
          {
            path: "/handleDrag",
            name: "游戏",
            meta: {
              title: "游戏 - 青栀吖",
            },
            icon: "el-icon-message",
            component: () => import("../views/handleDrag"),
            hidden: false,
            childrens: false,
          },
          {
            path: "/contact",
            name: "留言",
            meta: {
              title: "留言 - 青栀吖",
            },
            icon: "el-icon-message",
            component: () => import("../views/contact/index"),
            hidden: false,
            childrens: false,
          },
          {
            path: "/aboutMe",
            name: "关于我",
            meta: {
              title: "关于我 - 青栀吖",
            },
            icon: "el-icon-message",
            component: () => import("../views/aboutMe"),
            hidden: false,
            childrens: false,
          },
        ],
      },
      {
        path: "/personal",
        name: "个人中心",
        meta: {
          title: "个人中心 - 青栀吖",
        },
        icon: "el-icon-user",
        component: () => import("../views/personal"),
        hidden: false,
        childrens: false,
      },
      {
        path: "/articleDetails/:id",
        name: "articleDetails",
        meta: {
          title: "详情 - 青栀吖",
        },
        component: () => import("../views/articleDetails"),
        hidden: true,
        childrens: false,
      },
      {
        path: "/login",
        name: "登录",
        meta: {
          title: "登录 - 青栀吖",
        },
        component: () => import("../views/Login/index"),
        hidden: true,
        childrens: false,
      },
      {
        path: "/register",
        name: "注册",
        meta: {
          title: "注册 - 青栀吖",
        },
        component: () => import("../views/register"),
        hidden: true,
        childrens: false,
      },
    ],
  },
  {
    path: "/bricks",
    name: "别踩白块",
    meta: {
      title: "别踩白块 - 青栀吖",
    },
    component: () => import("../views/bricks"),
    hidden: true,
    childrens: false,
  },
  {
    path: "/works",
    name: "作品展示",
    meta: {
      title: "作品展示 - 青栀吖",
    },
    component: () => import("../views/works"),
    hidden: true,
    childrens: false,
  },
  {
    path: "/zuopinPDF/*",
    name: "作品展示",
    meta: {
      title: "作品展示 - 青栀吖",
    },
    component: () => import("../views/works/zuopinPDF"),
    hidden: true,
    childrens: false,
  },
  {
    path: "*",
    name: "404",
    component: () => import("../components/404"),
  },
];

const router = new VueRouter({
  mode: 'hash', // 去掉url中的#
  base: process.env.BASE_URL,
  routes
})


router.afterEach(route => {
  // 从路由的元信息中获取 title 属性
  if (route.meta.title) {
    document.title = route.meta.title;
    // 如果是 iOS 设备，则使用如下 hack 的写法实现页面标题的更新
    if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      const hackIframe = document.createElement('iframe');
      hackIframe.style.display = 'none';
      hackIframe.src = '/static/html/fixIosTitle.html?r=' + Math.random();
      document.body.appendChild(hackIframe);
      setTimeout(_ => {
        document.body.removeChild(hackIframe)
      }, 300)
    }
  }
});

const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originPush.call(this, location).catch(err => err)
}

//获取开始时间
let startTime = Date.now();
//定义一个获取用户的函数
const get_userinfo = (to, from, token) => {
//获取当前时间
  let currentTime = Date.now();
  let Brow_history = {
    preUrl: from.path,
    preTitle: from.meta.title,
    currentUrl: to.path,
    currentTitle: to.meta.title,
    prePage_browTime: parseInt((currentTime - startTime) / 1000) + "s",
  };
  if(token){
    Brow_history.user = JSON.parse(sessionStorage.getItem("user"));
  }
  // console.log(Brow_history);
  // console.log(
  //   "用户由 ",
  //   from.path,
  //   " 页面 跳转到 ",
  //   to.path,
  //   " 页面，在 ",
  //   from.path,
  //   " 页面停留了 ",
  //   currentTime - startTime,
  //   "毫秒。转换成秒数约为：",
  //   parseInt((currentTime - startTime) / 1000)
  // );
  //初始化开始时间
  startTime = Date.now();
  // console.log("======== 分割线 ========");
};

router.beforeEach((to, from, next) => {
  // get_userinfo(to, from);
  // next();
  // NProgress.start();
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  }
  if (to.meta.requireAuth == true) {
    const token = getToken();
    //需要登录权限进入的路由
    if (token) {
    //调用刚才定义的函数
      get_userinfo(to, from, token);
      next();
    } else {
      Message.error("未登录");
      next({ name: "login" });
    }
  } else {
    get_userinfo(to, from);
    //不需要登录权限
    next();
  }
  next();
});


export default router
