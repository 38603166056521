import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:localStorage.getItem("token"),
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    isLogin:localStorage.getItem("isLogin"),
    anntMsgTotal:localStorage.getItem("anntMsgTotal"),
    routes:[],
  },
  getters: {
    //get this.$store.getters.getUser
    getUser: state => {
      return state.userInfo
    },
    getToken: state => {
      return state.token
    },
    isLogin: state => {
      return state.isLogin
    },
    anntMsgTotal: state => {
      return state.anntMsgTotal
    }
  },
  mutations: {
    //set
    SET_TOKEN: (state, token) =>{
      state:token = token
      localStorage.setItem("token", token)
    },
    SET_USERINFO: (state, userInfo) =>{
      state:userInfo = userInfo
      localStorage.setItem("userInfo", JSON.stringify(userInfo))
      // sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
    },
    //set
    SET_isLogin: (state, isLogin) =>{
      state:isLogin = isLogin
      localStorage.setItem("isLogin", isLogin)
    },
    //set
    SET_anntMsgTotal: (state, anntMsgTotal) =>{
      state:anntMsgTotal = anntMsgTotal
      localStorage.setItem("anntMsgTotal", anntMsgTotal)
    },
    SET_TOKEN_NONE: (state, token) =>{
      state:token = ''
      localStorage.setItem("token", null)
    },
    SET_USERINFO_NONE: (state, userInfo) =>{
      state:userInfo = {}
      localStorage.setItem("userInfo", JSON.stringify(''))
      // sessionStorage.setItem("userInfo", JSON.stringify(''))
    },
    SET_isLogin_NONE: (state, isLogin) =>{
      state:isLogin = 0
      localStorage.setItem("isLogin", 0)
    },
    SET_anntMsgTotal_NONE: (state, anntMsgTotal) =>{
      state:anntMsgTotal = 0
      localStorage.setItem("anntMsgTotal", 0)
    },
    initRoutes(state,data){
      state.routes = data
    }
  },
  actions: {

  },
  modules: {

  },
})
